import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Avatar,
  Popover,
  Table,
  Row,
  Col,
  Divider,
  Image,
} from "antd";
import Logo from "../../assets/Images/logo-carker-naranja.png";
import Logo2 from "../../assets/Images/logo-carker-naranja@2x.png";
import avatar from "../../assets/Images/empty_avatar.svg";
import SearchIco from "../../assets/Images/search.svg";
import suIco from "../../assets/Images/su-ico.png";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { DashboardAPI } from "../../app/Services/axios";
import { getSasToken } from "../../stores/selectors";
import "./SuperUserNavBar.css";
import { CurrentUser } from "../../context/UserContext";
import { useHistory } from "react-router-dom";

export interface SearchInc {
  azureCode: string;
  dateOfBirth: string;
  email: string;
  globalRoles: any;
  id: string;
  isTemporal: string;
  isWorkshopUser: string;
  lastName: string;
  name: string;
  phone: string;
  photoUrl: string;
  sex: string;
  userRoles: any;
  zipCode: string;
  source: string;
  isEnabled?: string;
}

interface IProps {
  loading?: boolean;
  logout: Function;
  login: Function;
}

type menuList =
  | "su"
  | "diag"
  | "quot"
  | "query"
  | "rate"
  | "report"
  | "ws"
  | "wsList"
  | "history"
  | "blog"
  | "talleres-destacados"
  | "banners"
  | undefined;

export const SuperUserNavBar: React.FC<IProps> = ({
  loading,
  logout,
  login,
}) => {
  const history = useHistory();
  const user = React.useContext(CurrentUser);
  const sasToken = useSelector(getSasToken);

  return !user ? (
    <div className="su-navbar">
      <div className="su-nav-btn">
        {!loading && (
          <Button
            style={{
              background: "#f05822",
              color: "white",
              border: "1px solid #ffffff",
              height: "32px",
              width: "108px",
              lineHeight: "inherit",
              marginRight: 10,
              padding: 10,
              borderRadius: 10,
            }}
            onClick={() => login()}
          >
            <p
              style={{
                margin: "-5px 0 0 0",
                fontWeight: "600",
                fontFamily: "var(--font-family)",
              }}
            >
              Iniciar sesión
            </p>
          </Button>
        )}
      </div>
    </div>
  ) : (
    <div className="su-navbar">
      <div className="su-nav-username">
        <Popover
          trigger="hover"
          overlayStyle={{ zIndex: 3000 }}
          content={
            <Row align="middle" style={{ width: "233px" }} gutter={[10, 10]}>
              <Col onClick={() => history.push("/configuracion")}>
                <Button
                  style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    color: "#0C479D",
                    borderColor: "#D1D3D4",
                    width: "223px",
                    height: "49px",
                    transform: "skew(-20deg)",
                    marginBottom: "5px",
                  }}
                >
                  <div style={{ transform: "skew(20deg)" }}>Configuración</div>
                </Button>
              </Col>
              {user?.globalRoles &&
                user.globalRoles[0].roleCode === "ADMIN" && (
                  <Col onClick={() => history.push("/configurarUsuarios")}>
                    <Button
                      style={{
                        backgroundColor: "rgba(0,0,0,0)",
                        color: "#0C479D",
                        borderColor: "#D1D3D4",
                        width: "223px",
                        height: "49px",
                        transform: "skew(-20deg)",
                      }}
                    >
                      <div style={{ transform: "skew(20deg)" }}>
                        Administrar Super Usuarios
                      </div>
                    </Button>
                  </Col>
                )}
            </Row>
          }
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              fontFamily: "var(--font-family)",
              fontWeight: 600,
              marginBottom: 12,
            }}
          >
            {user?.name} {user?.lastName}&nbsp;&nbsp;
            <DownOutlined width={15} style={{ color: "#fffff" }} />
            &nbsp;
          </p>
        </Popover>
      </div>
      <div className="su-nav-avatar">
        <Avatar
          style={{
            backgroundColor: "#F8F8F8",
            cursor: "pointer",
            width: "100%",
            height: "100%",
          }}
          icon={<img src={avatar} alt="avatar"></img>}
          src={
            sasToken && user && user.photoUrl && user.photoUrl.trim() !== ""
              ? `${user?.photoUrl}?${sasToken}`
              : avatar
          }
          onError={() => true}
        />
      </div>
      <div className="su-nav-btn">
        <Button
          style={{
            background: "#f05822",
            color: "white",
            border: "1px solid #ffffff",
            height: "32px",
            width: "108px",
            lineHeight: "inherit",
            marginRight: 10,
            padding: 10,
            borderRadius: 10,
          }}
          onClick={() => {
            history.push("/");
            logout();
          }}
        >
          <p
            style={{
              margin: "-6px 0 0 0",
              fontWeight: 600,
              fontFamily: "var(--font-family)",
            }}
          >
            Cerrar sesión
          </p>
        </Button>
      </div>
    </div>
  );
};

export const SuperUserSubNavBar: React.FC = () => {
  const history = useHistory();

  const user = React.useContext(CurrentUser);
  const [suNavForm] = Form.useForm();

  const [loading, setloading] = useState<boolean>(true);

  const [logState, setLogState] = useState<string>("Wait");
  const [current, setCurrent] = useState<menuList>(undefined);

  const [listData, setListData] = useState([]);
  const [showCreate, setShowCreate] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [visibleOptions, setVisibleOptions] = React.useState<boolean>(false);

  const [zoomLevel, setZoomLevel] = React.useState<number>(
    parseInt((((window.outerWidth - 10) / window.innerWidth) * 100).toString()),
  );

  let timer: any;

  const updateZoomLevel = () => {
    timer =
      !timer &&
      setInterval(() => {
        const currentLevel = parseInt(
          (((window.outerWidth - 10) / window.innerWidth) * 100).toString(),
        );
        if (currentLevel != zoomLevel) {
          setZoomLevel(currentLevel);
        }
      }, 1500);
  };

  React.useEffect(() => {
    updateZoomLevel();

    return () => clearInterval(timer);
  }, [zoomLevel]);

  React.useEffect(() => {
    if (user?.globalRoles && user.globalRoles[0].roleCode === "ADMIN") {
      setShowCreate(true);
    }
  }, [user]);

  const getId = async () => {
    setloading(true);
    await suNavForm.validateFields().then((values) => {
      setLogState("Wait");

      if (
        values.searchInput == "" ||
        values.searchInput == null ||
        values.searchInput == undefined
      ) {
        setLogState("Cleansed");
      }

      if (values.searchInput) {
        setListData([]);
        DashboardAPI.getUserByMail(values.searchInput, "")
          .then((result) => {
            let tempArray;
            let len = result.data.length;
            if (len == 1) {
              result.data.map((data: any) => {
                setLogState("Charging");

                Promise.resolve().finally(() => {
                  suNavForm.resetFields(["searchInput"]);
                  history.push(
                    `/SuperEndConsumerTable/${data.id}/${data.email}/`,
                  );
                });
              });
            } else if (len < 1) {
              setLogState("Non-existent");
            } else {
              setLogState("Correct");

              tempArray = result.data.map((data: any) => {
                if (
                  data?.id == undefined ||
                  data?.email == undefined ||
                  data?.name == undefined
                ) {
                } else {
                  return {
                    id: data?.id,
                    email: data?.email,
                    name: data?.name,
                  };
                }
              });
              tempArray = tempArray.filter((rest: any) => rest != undefined);

              setListData(tempArray);
            }
          })
          .finally(() => {
            setloading(false);
          })
          .catch((err) => {
            setLogState("Error");
          });
      }
    });
  };

  const PopoverRender = () => {
    switch (logState) {
      case "Wait":
        return (
          <div className="search-popover-cleansed">
            <p>Un momento...</p>
          </div>
        );
      case "Cleansed":
        return (
          <div className="search-popover-cleansed">
            <p>Asegúrate de ingresar un correo o nombre para poder buscar</p>
          </div>
        );
      case "Error":
        return (
          <div className="search-popover-error">
            <p>¡No se pudo encontrar coincidencias!</p>
          </div>
        );
      case "Correct":
        return (
          <div className="search-popover">
            <div className="search-popover-head">
              <p>Correo registrado, ¿quieres cargar los datos?</p>
            </div>
            <div className="search-popover-subhead">
              <p>
                Encontramos estos clientes con el mismo correo, por favor elige
                uno para cargar sus datos
              </p>
            </div>
            <div className="search-popover-table">
              <div className="search-popover-table-content">
                <Table
                  dataSource={listData}
                  style={{ width: "100%", height: "100%" }}
                  size="small"
                  loading={loading}
                  pagination={{
                    defaultPageSize: 6,
                    showSizeChanger: false,
                    pageSizeOptions: ["6"],
                  }}
                >
                  <Table.Column
                    title="Nombre"
                    dataIndex="name"
                    key="name"
                    render={(name, row: any) => (
                      <>
                        <div
                          onClick={() => {
                            setVisible(false);
                            Promise.resolve().finally(() => {
                              suNavForm.resetFields(["searchInput"]);
                              //@ts-ignore
                              history.push(
                                `/SuperEndConsumerTable/${row.id}/${row.email}/`,
                              );
                            });
                          }}
                          className="su-nav-table-container-label"
                        >
                          <p className="su-nav-table-label">{name}</p>
                        </div>
                      </>
                    )}
                  />
                  <Table.Column
                    title="Correo"
                    dataIndex="email"
                    key="email"
                    render={(email, row: any) => (
                      <>
                        <div
                          onClick={() => {
                            setVisible(false);
                            Promise.resolve().finally(() => {
                              suNavForm.resetFields(["searchInput"]);
                              //@ts-ignore
                              history.push(
                                `/SuperEndConsumerTable/${row.id}/${row.email}/`,
                              );
                            });
                          }}
                          className="su-nav-table-container-label"
                        >
                          <p className="su-nav-table-label">{email}</p>
                        </div>
                      </>
                    )}
                  />
                </Table>
              </div>
            </div>
          </div>
        );
      case "Charging":
        return (
          <div className="search-popover-charging">
            <p>¡Genial, lo encontramos!</p>
          </div>
        );
      case "Non-existent":
        return (
          <div className="search-popover-non-existent">
            <p>¡No se pudo encontrar coincidencias!</p>
          </div>
        );
      default:
        break;
    }
  };

  const SubUserRender = () => {
    return (
      <div
        className="menu-popover"
        style={{ backgroundColor: "white", marginLeft: "30px" }}
      >
        <Row align="middle" style={{ width: "233px" }} gutter={[10, 10]}>
          <Col onClick={() => history.push("/configuracion")}>
            <Button
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                color: "#0C479D",
                borderColor: "#D1D3D4",
                width: "223px",
                height: "49px",
                transform: "skew(-20deg)",
                marginBottom: "5px",
              }}
            >
              <div style={{ transform: "skew(20deg)" }}>Configuración</div>
            </Button>
          </Col>
          {user?.globalRoles && user.globalRoles[0].roleCode === "ADMIN" && (
            <Col onClick={() => history.push("/configurarUsuarios")}>
              <Button
                style={{
                  backgroundColor: "rgba(0,0,0,0)",
                  color: "#0C479D",
                  borderColor: "#D1D3D4",
                  width: "223px",
                  height: "49px",
                  transform: "skew(-20deg)",
                }}
              >
                <div style={{ transform: "skew(20deg)" }}>
                  Administrar Super Usuarios
                </div>
              </Button>
            </Col>
          )}
        </Row>
      </div>
    );
  };

  const OptionsRender = () => {
    return (
      <div
        className="menu-popover menu-items"
        style={{
          padding: "15px",
        }}
      >
        <Popover
          placement="bottomRight"
          arrowPointAtCenter
          color="white"
          overlayInnerStyle={{
            marginTop: "-20px",
            padding: "15px",
            borderRadius: "10px",
          }}
          trigger="hover"
          overlayStyle={{ zIndex: 3000 }}
          content={SubUserRender}
        >
          <Button
            className="menu-items-options-su"
            style={{
              background: "#f05822",
              color: "white",
            }}
          >
            <Image
              preview={false}
              src={suIco}
              style={{
                width: "30px",
                height: "auto",
                margin: "0px",
                padding: "0px",
              }}
            />
            {user?.name} {user?.lastName}
          </Button>
          <Divider className="menu-popover-divider" />
        </Popover>

        <Button
          className="menu-items-options"
          onClick={() => {
            history.push(`/SuperDiagnostic`);
            setCurrent("diag");

            setVisibleOptions(false);
          }}
        >
          Diagnósticos
        </Button>

        <Button
          className="menu-items-options"
          onClick={() => {
            history.push(`/SuperQuotation`);
            setCurrent("quot");

            setVisibleOptions(false);
          }}
        >
          Cotizaciones
        </Button>

        <Button
          className="menu-items-options"
          onClick={() => {
            history.push(`/SuperCommunication`);
            setCurrent("query");

            setVisibleOptions(false);
          }}
        >
          Comunicaciones
        </Button>

        <Button
          className="menu-items-options"
          onClick={() => {
            history.push(`/SuperRatings`);
            setCurrent("rate");

            setVisibleOptions(false);
          }}
        >
          Calificaciones
        </Button>

        <Button
          className="menu-items-options"
          onClick={() => {
            history.push(`/Reports`);
            setCurrent("report");

            setVisibleOptions(false);
          }}
        >
          Reportes
        </Button>

        <Button
          className="menu-items-options"
          onClick={() => {
            history.push(`/BuscarTaller`);
            setCurrent("ws");

            setVisibleOptions(false);
          }}
        >
          Administrar talleres
        </Button>

        <Button
          className="menu-items-options"
          onClick={() => {
            history.push(`/superblacklist`);
            setCurrent("wsList");

            setVisibleOptions(false);
          }}
        >
          Listado de talleres
        </Button>

        {showCreate && (
          <Button
            className="menu-items-options"
            onClick={() => {
              history.push(`/History`);
              setCurrent("history");

              setVisibleOptions(false);
            }}
          >
            Historial de cambios
          </Button>
        )}

        <Button
          className="menu-items-options"
          onClick={() => {
            history.push(`/talleres-destacados`);
            setCurrent("talleres-destacados");

            setVisibleOptions(false);
          }}
        >
          Talleres destacados
        </Button>

        <Button
          className="menu-items-options"
          onClick={() => {
            history.push("/banners");
            setCurrent("banners");

            setVisibleOptions(false);
          }}
        >
          Banner dashboard
        </Button>

        <Button
          className="menu-items-options"
          onClick={() => {
            history.push(`/BlogDashboard`);
            setCurrent("blog");

            setVisibleOptions(false);
          }}
        >
          Blog dashboard
        </Button>
      </div>
    );
  };

  React.useEffect(() => {
    if (user?.globalRoles && user.globalRoles[0].roleCode === "ADMIN") {
      setShowCreate(true);
    }
    if (document.location.pathname.toLowerCase() === "/supercommunication")
      setCurrent("query");
    if (document.location.pathname.toLowerCase() === "/superratings")
      setCurrent("rate");
    if (document.location.pathname.toLowerCase() === "/history")
      setCurrent("history");
    if (document.location.pathname.toLowerCase() === "/superblacklist")
      setCurrent("wsList");
    if (document.location.pathname.toLowerCase() === "/BlogDashboard")
      setCurrent("blog");
    if (document.location.pathname.toLowerCase() === "/talleres-destacados")
      setCurrent("talleres-destacados");
    if (document.location.pathname.toLowerCase() === "/banners")
      setCurrent("banners");
  }, []);

  React.useEffect(() => {
    history.listen((location) => {
      if (location.pathname.toLowerCase() === "/supercommunication")
        setCurrent("query");
      if (location.pathname.toLowerCase() === "/superratings")
        setCurrent("rate");
      if (location.pathname.toLowerCase() === "/history") setCurrent("history");
      if (location.pathname.toLowerCase() === "/superblacklist")
        setCurrent("wsList");
      if (document.location.pathname.toLowerCase() === "/BlogDashboard")
        setCurrent("blog");
      if (document.location.pathname.toLowerCase() === "/talleres-destacados")
        setCurrent("talleres-destacados");
      if (document.location.pathname.toLowerCase() === "/banners")
        setCurrent("banners");
    });
  }, [history]);

  return (
    <div className="su-subnav">
      <div className="su-subnav-logo">
        <img
          src={Logo}
          srcSet={Logo2}
          alt="CarKer logo"
          width="205px"
          height="80px"
          object-fit="contain"
          onClick={() => {
            history.push("/");
          }}
          style={{ cursor: "pointer", position: "relative" }}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="su-subnav-content">
          <Form className="su-subnav-search no-padding-left" form={suNavForm}>
            <Popover
              visible={visible}
              placement="bottomRight"
              content={PopoverRender}
              color="white"
              trigger="click"
              onVisibleChange={(change) => setVisible(change)}
            >
              <Button
                style={{
                  background: "#37d16a",
                  color: "white",
                  border: "none",
                  height:
                    zoomLevel >= 170
                      ? zoomLevel < 190
                        ? "39.75px"
                        : "34.45px"
                      : "53px",
                  padding:
                    zoomLevel >= 170
                      ? zoomLevel < 190
                        ? "7.5px"
                        : "6.5px"
                      : "10px",
                  width: "80px",
                  lineHeight: "inherit",
                  marginRight: -1,
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  fontFamily: "Emprint-bold !important",
                }}
                icon={
                  <img
                    src={SearchIco}
                    className="img-SearchIco"
                    alt="SearchIco"
                  />
                }
                onClick={() => {
                  getId();
                }}
              ></Button>
            </Popover>
            <Form.Item className="su-subnav-search-form" name="searchInput">
              <Input
                className="su-subnav-search-input"
                style={{
                  height:
                    zoomLevel >= 170
                      ? zoomLevel < 190
                        ? "39.75px"
                        : "34.45px"
                      : "53px",
                  fontSize:
                    zoomLevel >= 170
                      ? zoomLevel < 190
                        ? "10.5px"
                        : "9.1px"
                      : "14px",
                  width:
                    zoomLevel >= 170
                      ? zoomLevel < 190
                        ? "218.25px"
                        : "189.15px"
                      : "291px",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getId();
                    setVisible(true);
                  }
                }}
                placeholder="Nombre o Correo electrónico de cliente"
              />
            </Form.Item>
            <Popover
              className="menu-popover-align"
              visible={visibleOptions}
              placement="bottomRight"
              content={OptionsRender}
              trigger="click"
              onVisibleChange={(change) => setVisibleOptions(change)}
              arrowPointAtCenter
              color="#f05822"
              overlayInnerStyle={{
                padding: "0px",
                borderRadius: "10px",
              }}
            >
              <div className="div-pressable">
                <Button
                  className="menu-button"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  <span>Menú</span>
                  <MenuOutlined color={"black"} />
                </Button>
              </div>
            </Popover>
          </Form>
          <div className="su-subnav-right-buttons"></div>
        </div>
      </div>
    </div>
  );
};
